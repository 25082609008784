.listMargin {
	margin: 28px 16px 16px 16px;
}

.title {
	width: 100%;
	max-width: 400px;
	
	display: block;
	margin: 0 auto 64px;
}

.wideButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	
	cursor: pointer;
	
	margin-bottom: 32px;
	
	color: var(--color-primary);
}

.wideButton :first-child {
	margin-right: 16px;
}

.tileButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	
	margin-top: 16px;
	padding: 8px;
	
	text-decoration: none;
	color: var(--color-primary);
	font-weight: 600;
}

.tileButtonIcon {
	width: 48px;
	height: 48px;
	margin-right: 8px;
	
	flex-shrink: 0;
}

.tileButtonLabel {
	flex-grow: 1;
	text-align: center;
	margin-right: 56px;
}

.footer {
	display: block;
	margin: 32px 16px;
	color: var(--color-secondary);
}