.normal {
	color: var(--color-primary);
}

.group {
	color: #DA0000;
}

.groupExpanded {
	transform: rotate(90deg);
}