.main {
	opacity: 0.4;
	font-size: 12px;
	
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}

.main img {
	width: 16px;
	height: 16px;
}