.main {
	padding: 32px;
}

.body {
	color: var(--color-secondary);
}

.buttonLink {
	display: flex;
	flex-direction: row;
	align-items: center;
	
	padding-top: 8px;
	padding-bottom: 8px;
	
	color: var(--color-primary);
	text-decoration: none;
	font-weight: 500;
}

.buttonLink span {
	flex-grow: 1;
}