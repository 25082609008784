.main {
	padding: 32px;
	
	overflow-wrap: break-word;
	word-wrap: break-word;
	
	word-break: break-word;
	
	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.rawDisplay {
	white-space: pre-wrap;
}

.attachmentMargin {
	margin-top: 8px;
}

.main img {
	width: 100%;
	border-radius: 4px;
}

.attachmentLink {
	width: 100%;
	
	box-sizing: border-box;
	padding: 8px;
	
	text-decoration: none;
	color: var(--color-secondary);
	
	background-color: rgba(0, 0, 0, 0.05);
	
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	
	
	display: flex;
	flex-direction: row;
	align-items: center;
}

.attachmentLink span {
	flex-grow: 1;
}

.icon {
	width: unset !important;
}