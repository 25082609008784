.main {
	width: 100%;
	
	padding: 16px 8px;
	box-sizing: border-box;
	
	cursor: pointer;
}

.main span {
	display: block;
}

.title {
	font-weight: 500;
	
	margin-top: 4px;
	margin-bottom: 8px;
	
	color: var(--color-primary);
}

.description {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	
	font-size: 14px;
	
	color: var(--color-secondary);
}