.background {
	width: 100%;
	height: 60px;
	
	/*background-color: var(--color-card);*/
	/*box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);*/
	background-color: var(--color-toolbar);
	
	position: sticky;
	top: 0;
	
	z-index: 10;
	
	padding-top: env(safe-area-inset-top);
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	
	font-weight: bold;
}

.button {
	border: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	
	padding: 16px;
}

.buttonPlaceholder {
	width: 16px;
	height: 16px;
	padding: 16px;
}

.title {
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	color: white;
}

.searchField {
	flex-grow: 1;
	font-size: 16px;
	
	background: rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	border: none;
	outline: 0;
	padding: 12px;
	margin-left: 12px;
	color: white;
}