.background {
	width: 100%;
	
	background-color: var(--color-toolbar);
	
	position: sticky;
	
	z-index: 10;
	
	padding-top: env(safe-area-inset-top);
	
	margin-bottom: 12px;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	
	font-weight: bold;
	
	padding-top: 24px;
	padding-bottom: 48px;
}

.logo {
	width: 180px;
	
	user-select: none;
}

.actionPlaceholder {
	width: 16px;
	padding: 6px;
	margin-left: 16px;
	margin-right: 16px;
}

.actionButton {
	padding: 6px;
	margin-left: 16px;
	margin-right: 16px;
}

.searchContainer {
	position: relative;
}

.search {
	margin: 16px;
	padding: 8px 16px;
	
	position: absolute;
	left: 0;
	right: 0;
	transform: translateY(-42px);
	
	display: flex;
	flex-direction: row;
	align-items: center;
	/*justify-content: center;*/
	
	text-decoration: none;
	color: unset;
}

.search :first-child {
	margin-right: 8px;
}