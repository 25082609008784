.list {
	padding: 28px;
}

.sectionHeader {
	padding: 0 8px;
	font-weight: bold;
}

.labelNoResults {
	color: var(--color-secondary);
	text-align: center;
}