.main {
	width: 100%;
	
	padding: 8px;
	box-sizing: border-box;
	
	cursor: pointer;
}

.mainInset {
	composes: main;
	
	padding: 8px 0;
}

.textPrimary {
	font-weight: 500;
	color: var(--color-primary);
}

.textSecondary {
	color: var(--color-secondary);
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.container svg {
	padding-left: 8px;
	flex-shrink: 0;
}

.childrenContainer {
	padding-top: 8px;
}