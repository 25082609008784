:root {
	/*--color-background: rgb(245, 239, 239);*/
	--color-background: #F3F3F3;
	--color-background_scrim: rgba(245, 239, 239, 0.72);
	--color-card: white;
	
	/*--color-primary: rgba(0, 0, 0, 0.87);*/
	--color-primary: #0B0D16;
	/*--color-secondary: rgba(0, 0, 0, 0.54);*/
	--color-secondary: #505257;
	--color-disabled: rgba(0, 0, 0, 0.38);
	
	--color-toolbar: #191E34;
}

.invertableLight {
	filter: invert(100%);
}

@media(prefers-color-scheme: dark) {
	:root {
		--color-background: rgb(18, 18, 18);
		--color-background_scrim: rgba(18, 18, 18, 0.72);
		--color-card: #1E1E1E;
		
		--color-primary: rgba(255, 255, 255, 1);
		--color-secondary: rgba(255, 255, 255, 0.7);
		--color-disabled: rgba(255, 255, 255, 0.5);
	}
	
	.invertable {
		filter: invert(100%);
	}
	
	.invertableLight {
		filter: unset;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	color: var(--color-secondary);
	background-color: var(--color-background);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

/* Limit width */
.content {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.content--padding {
	padding: 16px;
}

.content--padding_insets {
	padding-left: calc(32px + env(safe-area-inset-left));
	padding-right: calc(32px + env(safe-area-inset-right));
	padding-top: calc(32px + env(safe-area-inset-top));
	padding-bottom: calc(32px + env(safe-area-inset-bottom));
}

/* Appearance of a floating card with rounded edges */
.card {
	background-color: var(--color-card);
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/*
   A big container that takes up most of the screen
   and centers its contents in a column
*/
.centerContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
	width: 100%;
	height: 50vh;
}

h1,h2,h3,h4,h5,h6 {
	color: var(--color-primary);
}

.text_primary {
	color: var(--color-primary);
}

.text_secondary {
	color: var(--color-secondary);
}

.text_disabled {
	color: var(--color-disabled);
}